import { render, staticRenderFns } from "./AddPackageModules.vue?vue&type=template&id=009c0790&scoped=true&"
import script from "./AddPackageModules.vue?vue&type=script&lang=js&"
export * from "./AddPackageModules.vue?vue&type=script&lang=js&"
import style0 from "./AddPackageModules.vue?vue&type=style&index=0&id=009c0790&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "009c0790",
  null
  
)

export default component.exports